import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from "react-helmet";
import VillaMaps from "./VillaMap";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import "tw-elements";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Villa() {
  const [villa, setVilla] = useState(null);
  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{...}`, { slug })
      .then((data) => setVilla(data[0]))
      .catch(console.error);
  }, [slug]);

  console.log(villa);

  if (!villa)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <i className="fa-solid fa-sync fa-spin text-4xl"></i>
      </div>
    );

  return (
    <section
      key={villa._id}
      className="container w-full lg:px-0 px-5 lg:w-full mx-auto min-h-screen"
    >
      <Helmet>
        <title>{villa.title} | موقع أجار Ajar</title>
        <meta name="description" content={villa.description} />
      </Helmet>

      {/* <a href="/allvillas">
        <div className="w-100 h-100 border-black bg-yellow-400 shadow-lg hover:shadow-sm rounded-full fixed bottom-20 right-10 scroll-my-10 will-change-auto hover:will-change-scroll  py-4 justify-center grid whitespace-nowrap ring-3 hover:ring-yellow-400">
          <i className="fa-light fa-angle-left text-2xl"></i>
        </div>
      </a> */}

      <div className=" grid">
        <div className=" w-full lg:h-[600px] rounded-b-3xl place-content-center items-center overflow-hidden ">
          <img
            src={urlFor(villa.mainImage).url()}
            className=" object-center"
            alt={villa.title}
          ></img>
        </div>

        <div className="flex flex-wrap flex-col text-center place-items-center my-4 mt-10 bg-white  ">
          <div className=" mb-4">
            <h1 className="text-4xl text-slate-700 font-extrabold leading-none lg:text-[72px] w-full">
              {villa.title}
            </h1>
          </div>

          <div
            style={{ display: `${villa.hidePrice}` }}
            className=" text-black"
          >
            <h1 className="text-4xl text-slate-700 font-bold leading-none lg:text-[48px] w-full">
              ابتداءً من {villa.price}
              <i className="fa-solid fa-shekel-sign text-2xl mr-2 lg:text-[36px] "></i>
            </h1>
          </div>
          <div
            style={{ display: `${villa.hideWeekendPrice}` }}
            className=" text-gray-700 p-2 m-2 md:p-4 md:m-4 bg-blue-50  border-gray-200 border-2 border-solid rounded-xl"
          >
            <h2 className="text-lg text-gray-500 leading-none lg:text-[18px] w-full">
              سعر نهاية الأسبوع و الأعياد {villa.priceWeekend}
              <i className="fa-solid fa-shekel-sign text-xl mr-2 text-[10px] lg:text-[12px] "></i>
            </h2>
          </div>

          <div className="col-span-5  w-full my-10  text-center text-2xl text-gray-500 hover:text-gray-700 ">
            <BlockContent
              blocks={villa.body}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
            <p className=" mt-5 text-gray-500 hover:text-gray-700">
              <span className="font-bold text-2xl text-gray-500">
                <i className="fa-light fa-map-location-dot ml-4"></i>العنوان :{" "}
              </span>
              {villa.location_text}
            </p>
          </div>
          <div className="text-yellow-500 w-full">
            <div className="flex flex-wrap w-full text-4xl md:text-[56px] gap-8 justify-center">
              <div style={{ display: `${villa.socialMedia.showFacebook}` }}>
                <a
                  href={
                    "https://www.facebook.com/" + villa.socialMedia.facebook
                  }
                  target="_blank"
                  rel="noreferrer"
                  title="فيسبوك"
                  onClick={() => gaEventTracker(`${villa.title} Facebook`)}
                >
                  <i
                    className="fa-brands fa-facebook ml-2 w-full hover:text-blue-700"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <div style={{ display: `${villa.socialMedia.showInstagram}` }}>
                <a
                  href={
                    "https://www.instagram.com/" + villa.socialMedia.instagram
                  }
                  target="_blank"
                  rel="noreferrer"
                  title="انستغرام"
                  onClick={() => gaEventTracker(`${villa.title} Instagram`)}
                >
                  <i
                    className="fa-brands fa-instagram ml-2 w-full hover:text-pink-700"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <div style={{ display: `${villa.socialMedia.showTiktok}` }}>
                <a
                  href={"https://vt.tiktok.com/" + villa.socialMedia.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  title="تيكتوك"
                  onClick={() => gaEventTracker(`${villa.title} Tiktok`)}
                >
                  <i
                    className="fa-brands fa-tiktok ml-2 w-full hover:text-black"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <div style={{ display: `${villa.socialMedia.showSnapchat}` }}>
                <a
                  href={
                    "https://www.snapchat.com/add/" + villa.socialMedia.snapchat
                  }
                  target="_blank"
                  rel="noreferrer"
                  title="سنابشات"
                  onClick={() => gaEventTracker(`${villa.title} Snapchat`)}
                >
                  <i
                    className="fa-brands fa-snapchat ml-2 w-full hover:text-black"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END OF MAIN HEADER AND INFO */}

      <section
        name="التواصل"
        className="flex flex-row justify-around bg-white lg:gap-4 lg:max-w-6xl lg:justify-evenly justify-items-center place-content-center mx-auto"
      >
        <a
          href={"tel:" + villa.mobile}
          target="_blank"
          rel="noreferrer"
          title="الهاتف"
          className="w-full"
          style={{ display: `${villa.showCall}` }}
          onClick={() => gaEventTracker(`Call ${villa.slug.current} owner`)}
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-3xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-5
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-rose-700 hover:bg-rose-600 
            focus:shadow-gray-200"
          >
            <i
              className="fa-light fa-mobile-signal-out ml-2 w-full"
              aria-hidden="true"
            ></i>{" "}
            هاتف
          </div>
        </a>

        <a
          title={`موقع ${villa.title}`}
          rel="noreferrer"
          className="w-full"
          style={{ display: `${villa.showMap}` }}
          onClick={() => gaEventTracker(`Navigate to ${villa.slug.current}`)}
          href={
            "https://maps.google.com/?q=" +
            villa.location.lat +
            "," +
            villa.location.lng
          }
          key={villa.slug.current}
          target="_blank"
        >
          <div
            className=" 
            lg:w-full text-center mx-2 text-xl lg:text-3xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-5
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-blue-700 hover:bg-blue-600 
            focus:shadow-gray-200"
          >
            <i
              className="fa-light fa-map-location-dot ml-2 w-full"
              aria-hidden="true"
            ></i>{" "}
            الموقع
          </div>
        </a>

        <a
          title="واتساب"
          id="wa"
          rel="noreferrer"
          className="w-full"
          style={{ display: `${villa.socialMedia.showWhatsapp}` }}
          onClick={() => gaEventTracker(`Whatsapp ${villa.slug.current} owner`)}
          href={
            "https://wa.me/" +
            villa.countryCode +
            villa.mobile +
            "?text=مرحبا، أنا بتواصل معك عن طريق موقع " +
            " *Ajar - أجار* للفلل في أريحا و حابب استفسر عن " +
            villa.title +
            "%0a https://ajar.villas/villas/" +
            villa.slug.current +
            "%0a" +
            " شكرا"
          }
          target="_blank"
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-3xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-5
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-green-700 hover:bg-green-600 
            focus:shadow-gray-200"
          >
            <i
              className="fa-brands fa-whatsapp ml-2 w-full"
              aria-hidden="true"
            ></i>{" "}
            واتساب
          </div>
        </a>

        <a
          title="محادثة"
          id="wa"
          rel="noreferrer"
          className="w-full"
          style={{ display: `${villa.socialMedia.showChat}` }}
          onClick={() =>
            gaEventTracker(`Chat with ${villa.slug.current} owner`)
          }
          href={
            "https://tawk.to/chat/" +
            villa.socialMedia.propertyId +
            "/" +
            villa.socialMedia.widgetId
          }
          target="_blank"
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-3xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-5
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-yellow-600 hover:bg-yellow-500 
            focus:shadow-gray-200"
          >
            <i
              className="fa-light fa-comment-dots ml-2 w-full"
              aria-hidden="true"
            ></i>{" "}
            محادثة
          </div>
        </a>
      </section>

      {/* AMINITIES STARTS HERE */}
      <div className="text-4xl pt-20 pb-10 text-center text-gray-500 hover:text-gray-700 ">
        <i className="fa-light fa-face-smile-relaxed w-full"></i>
        <h2 className="text-xl font-bold lg:text-2xl rtl">
          وسائل الراحة في {villa.title}
        </h2>
      </div>
      <section
        name="وسائل الراحة"
        id="Aminities"
        className="grid grid-cols-3 gap-2 text-gray-500  place-content-center md:grid-cols-5  lg:grid-cols-7 lg:px-10"
      >
        <div
          style={{ display: `${villa.aminities.rooms}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i className="fa-light fa-bed  text-xl pb-2 lg:text-4xl "></i>
          <hr className="py-1 lg:py-2" />
          <p>الغرف</p>
          <p>
            <i
              className={`fa-regular fa-${villa.aminities.rooms}  text-xl lg:text-md w-full`}
              aria-hidden="true"
            ></i>
          </p>
        </div>

        <div
          style={{ display: `${villa.aminities.bathrooms}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i className="fa-light fa-toilet  text-xl pb-2 lg:text-4xl "></i>
          <hr className="py-1 lg:py-2" />
          <p>الحمامات</p>
          <p>
            <i
              className={`fa-regular fa-${villa.aminities.bathrooms}  text-xl lg:text-md w-full`}
              aria-hidden="true"
            ></i>
          </p>
        </div>

        <div
          style={{ display: `${villa.aminities.floors}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i className="fa-light fa-home  text-xl pb-2 lg:text-4xl "></i>
          <hr className="py-1 lg:py-2" />
          <p>الطوابق</p>
          <p>
            <i
              className={`fa-regular fa-${villa.aminities.floors}  text-xl lg:text-md w-full`}
              aria-hidden="true"
            ></i>
          </p>
        </div>
        <div
          style={{ display: `${villa.aminities.ac}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-air-conditioner  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>يوجد تكييف</span>
        </div>

        <div
          style={{ display: `${villa.aminities.pets}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className={`fa-light fa-paw  text-xl pb-2 lg:text-4xl ${villa.aminities.pets}`}
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>حيوانات اليفة</span>
        </div>

        <div className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900">
          <i
            className="fa-light fa-family  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>للعائلات فقط</span>
        </div>

        <div
          style={{ display: `${villa.aminities.bbq}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-shish-kebab  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>منطقة للشواء</span>
        </div>

        <div
          style={{ display: `${villa.aminities.tv}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-tv  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>تلفاز</span>
        </div>

        <div
          style={{ display: `${villa.aminities.internet}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-wifi  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>إنترنت سريع</span>
        </div>

        <div
          style={{ display: `${villa.aminities.washing}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-washing-machine  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>غسالة</span>
        </div>

        <div
          style={{ display: `${villa.aminities.coffee}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-coffee  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>ماكنة قهوة</span>
        </div>

        <div
          style={{ display: `${villa.aminities.cleaning}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-vacuum  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>خدمة التنظيف</span>
        </div>

        <div
          style={{ display: `${villa.aminities.pool}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-water-ladder  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>مسبح خارجي</span>
        </div>

        <div
          style={{ display: `${villa.aminities.kidsPool}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-person-swimming  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>مسبح للأطفال</span>
        </div>

        <div
          style={{ display: `${villa.aminities.outdoorBathrooms}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-restroom-simple  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>حمامات خارجية</span>
        </div>

        <div
          style={{ display: `${villa.aminities.jacouzi}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-hot-tub-person  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>جاكوزي</span>
        </div>

        <div
          style={{ display: `${villa.aminities.poolTable}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-pool-8-ball  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>طاولة بلياردو</span>
        </div>

        <div
          style={{ display: `${villa.aminities.soccerTable}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-futbol  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>طاولة كرة قدم</span>
        </div>

        <div
          style={{ display: `${villa.aminities.tennisTable}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-table-tennis-paddle-ball  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>طاولة تنيس</span>
        </div>

        <div
          style={{ display: `${villa.aminities.parking}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-circle-parking  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>مصف خاص للسيارة</span>
        </div>

        <div
          style={{ display: `${villa.aminities.electricCar}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-charging-station  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>شحن لسيارات الكهرباء</span>
        </div>

        <div
          style={{ display: `${villa.aminities.events}` }}
          className="bg-white col-span-1 shrink w-28 lg:w-32 rounded-md shadow-md shadow-gray-200 text-center items-center font-bold p-4 m-4 lg:p-5 lg:m-2 hover:shadow-sm hover:shadow-gray-900 hover:bg-gray-100  hover:text-gray-900"
        >
          <i
            className="fa-light fa-party-horn  text-xl pb-2 lg:text-4xl "
            aria-hidden="true"
          ></i>
          <hr className="py-1 lg:py-2" />
          <span>يصلح للإحتفالات</span>
        </div>
      </section>
      {/* end of Aminities */}

      <section
        name="الخريطة"
        className="text-center py-20 place-items-center text-gray-500 hover:text-gray-700"
      >
        <div className="text-4xl">
          <i className="fa-light fa-map w-full" aria-hidden="true"></i>
        </div>
        <h2 className="text-xl font-bold lg:text-2xl rtl">
          خريطة {villa.title}
        </h2>
        <p className="pb-10">{villa.title} على خرائط Google Maps</p>

        <VillaMaps />
      </section>

      <section
        name="الرزنامة"
        style={{ display: `${villa.socialMedia.showGoogleCalendar}` }}
        className="text-center py-20 place-items-center text-gray-500 hover:text-gray-700"
      >
        <div className="text-4xl">
          <i className="fa-light fa-map w-full" aria-hidden="true"></i>
        </div>
        <h2 className="text-xl font-bold lg:text-2xl rtl">
          رزنامة {villa.title}
        </h2>
        <p className="pb-10">{villa.title} على رزنامة Google Calendar</p>
        <iframe
          rel="noreferrer"
          title={`${villa.title}`}
          src={`https://calendar.google.com/calendar/u/0/embed?src=${villa.socialMedia.calendarId}&color=%237986CB`}
          style={{
            border: "0",
            backgroundColor: "#FFFFFF",
            width: "100%",
            height: "720px",
            frameborder: "0",
            scrolling: "no",
          }}
        ></iframe>
      </section>

      <section
        name="الصور"
        className="overflow-hidden text-gray-500 hover:text-gray-700 text-center "
      >
        <div className="text-4xl">
          <i className="fa-light fa-images w-full" aria-hidden="true"></i>
        </div>
        <h2 className="text-xl font-bold lg:text-2xl rtl">صور {villa.title}</h2>
        <p className="">{villa.gallery.images.length} صورة</p>

        <div className="container px-0 md:px-5 py-2 mx-auto lg:px-10">
          <div className="flex flex-wrap -m-1 md:-m-2">
            {villa.gallery.images.map((index) => {
              return (
                <div
                  className="flex flex-wrap w-full md:w-1/3"
                  key={villa.gallery.images + index._key}
                >
                  <div className="w-full p-1 md:p-2">
                    <a
                      href={`${urlFor(
                        villa.gallery.images + index.asset._ref
                      )}`}
                      alt={`${villa.title} - ${villa.location_text} - أريحا`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={urlFor(villa.gallery.images + index.asset._ref)}
                        alt={`${villa.title} - ${villa.location_text} - أريحا`}
                        className="block object-cover object-center w-full h-full rounded-lg"
                      />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* <BlockContent
          blocks={villa.gallery}
          projectId={sanityClient.clientConfig.projectId}
          dataset={sanityClient.clientConfig.dataset}
          className="grid grid-cols-1 lg:grid-cols-3 items-stretch w-full gap-4 object-center"
        /> */}

      <div className=" place-items-center text-center">
        <Link
          title="من نحن"
          rel="external allVillas dofollow"
          className="w-full cursor-pointer"
          to="/about"
          onClick={() => gaEventTracker("About us page")}
        >
          <h1 className="py-10 my-10">سجل في خدمة الفلل في أريحا</h1>
        </Link>
      </div>
    </section>
  );
}
