import React from "react";

const BannersSkeleton = () => {

  return (
    <section className="animate-pulse overflow-hidden  w-full text-gray-700 bg-white p-0 text-center ">
          <div className="mx-auto">
            <div className="flex gap-4 w-full flex-nowrap whitespace-nowrap">
                
                <div 
                className="flex flex-wrap justify-center z-100 snap-always snap-center  place-items-center text-center shadow-md bg-gray-600 shadow-gray-500  w-[360px] h-[550px] md:w-[1280px] md:h-[720px]"
                // style={{
                //   backgroundRepeat: "no-repeat",
                //   backgroundSize: "1280px",
                //   backgroundPosition: "center center",
                //   backgroundImage: `url(./images/skeleton.webp)`,
                // }}
                >
                 <div className="  text-center place-items-center justify-center m-auto p-10 bg-gray-700 md:rounded-lg opacity-50 ">
                    <h1
                      className="text-[56px] leading-none bg-white h-10 w-[220px] md:w-[400px] mb-6 rounded-full font-bold  place-items-center text-center drop-shadow-sm border-solid"
                      
                    >
                      
                    </h1>
                    <div className=" w-[220px] text-center align-middle place-items-center justify-center">
                      <hr className="animate-pulse" />
                      <h2 className="bg-white drop-shadow-sm text-center place-items-center justify-center justify-items-center shadow-black mt-4 h-6 w-[150px] mb-6 rounded-full" >
                        
                      </h2>
                    </div>
                  </div>
                </div>
                
                <div 
                className="flex flex-wrap justify-center z-100 snap-always snap-center  place-items-center text-center shadow-md bg-gray-500 shadow-gray-500  w-[360px] h-[550px] md:w-[1280px] md:h-[720px]"
                // style={{
                //   backgroundRepeat: "no-repeat",
                //   backgroundSize: "1280px",
                //   backgroundPosition: "center center",
                //   backgroundImage: `url(./images/skeleton.webp)`,
                // }}
                >
                 <div className="  text-center place-items-center justify-center m-auto p-10 bg-gray-700 md:rounded-lg opacity-50 ">
                    <h1
                      className="text-[56px] leading-none bg-white h-10 w-[220px] md:w-[400px] mb-6 rounded-full font-bold  place-items-center text-center drop-shadow-sm border-solid"
                      
                    >
                      
                    </h1>
                    <div className=" w-[220px] text-center align-middle place-items-center justify-center">
                      <hr className="animate-pulse" />
                      <h2 className="bg-white drop-shadow-sm text-center place-items-center justify-center justify-items-center shadow-black mt-4 h-6 w-[150px] mb-6 rounded-full" >
                        
                      </h2>
                    </div>
                  </div>
                </div>
                
                </div>
              </div>
              </section>
  );
};

export default BannersSkeleton;
