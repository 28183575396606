import React from "react";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-120222790-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const useAnalyticsEventTracker = (category="Villa Page") => {
  const eventTracker = (action = "Click", label = "button") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;
