import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import { useParams, Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import ScrollToTop from "../helpers/ScrollToTop";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import PropagateLoader from "react-spinners/PropagateLoader";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const AllVillas = () => {
  const [villas, setVillas] = useState(null);
  const [villaPrice, setVillaPrice] = useState("20000");
  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "villas" && price <= ${villaPrice} ]  | order(price desc)
        {...}      
        `
      )
      .then((data) => setVillas(data))
      .catch(console.error);
  }, [villaPrice, slug]);

  console.log(villas);

  if (!villas)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <React.Suspense
          fallback={
            <PropagateLoader color={"#eab305"} loading={loading} size={20} />
          }
        />
      </div>
    );

  return (
    <section className=" w-full mt-0 place-items-center object-center text-center scroll-smooth hover:scroll-auto lg:px-0 mx-auto">
      <ScrollToTop />
      <Helmet>
        <title>أجار - Ajar | جميع الفلل</title>
      </Helmet>
      <h3 className="w-full text-3xl text-gray-900 font-bold text-center py-10 bg-yellow-500 ">
        جميع الفلل المعروضة
      </h3>
      {/* <div className="flex lg:flex-row flex-col my-10 justify-center">
        <div className="flex items-center lg:mt-0 mt-5 gap-3 lg:flex-row flex-col">
          <input
            type="text"
            className="w-full lg:w-80 p-2 border-2 border-gray-100 rounded focus:outline-none"
          />
          <button
            style={{ backgroundColor: "#FE043C" }}
            className="rounded w-full lg:w-auto px-10 py-3 text-white"
          >
            Search
          </button>
        </div>
      </div>
      <hr className="my-10" /> */}
      <div className="container mx-auto w-full place-items-center text-center place-content-center justify-items-center ">
        <div className="gap-5 p-5 text-center items-center place-items-center mx-auto bg-gray-50 ">
          <div className=" flex flex-wrap text-center ">
            <div className="my-4 ml-2 text-xl md:w-auto ">
              بدي فيلا سعرها لغاية
            </div>
            <div className="mx-4  md:w-auto">
              <select
                onChange={(e) => setVillaPrice(e.target.value)}
                className="w-[100px] p-4 ring ring-gray-200 rounded-xl"
              >
                <option value="20000">الكل</option>
                <option value="1000">1000</option>
                <option value="1500">1500</option>
                <option value="2000">2000</option>
                <option value="2500">2500</option>
                <option value="3000">3000</option>
                <option value="3500">3500</option>
                <option value="4000">4000</option>
              </select>
            </div>
            <div className="my-0 mr-2 text-xl md:w-auto md:my-4">
              و ترتيب النتائج حسب الأسعار
            </div>
          </div>
        </div>

        <div className="grid grid-cols-9 flex-row gap-4 rounded-xl max-w-6xl place-content-center mx-auto ">
          <React.Suspense
            fallback={
              <PropagateLoader color={"#eab305"} loading={loading} size={20} />
            }
          >
            {villas &&
              villas.map((villa, index) => (
                <div
                  className=" col-span-9 lg:w-full lg:col-span-3 bg-gray-50 rounded-xl shadow-xl std-border hover:bg-white "
                  key={villa.title}
                >
                  <Link
                    to={"/villas/" + villa.slug.current}
                    key={villa.slug.current}
                    onClick={() => gaEventTracker(`${villa.slug.current}`)}
                  >
                    <div
                      key={index}
                      className="flex flex-col items-center  justify-items-center"
                    >
                      <img
                        src={urlFor(villa.mainImage)
                          .height(400)
                          .width(1000)
                          .url()}
                        alt={villa.title}
                        className="overflow-hidden rounded-t-xl border-4 mb-4 std-border"
                      />

                      {/* VILLA TITLE AND PRICE */}
                      <div className="grid grid-cols-2 my-4">
                        <div className=" text-gray-700 place-items-center text-center col-span-2">
                          <h1 className="text-2xl font-bold">{villa.title}</h1>
                          <hr />
                        </div>

                        <div className="flex flex-row mt-4 col-span-1 text-lg text-center text-gray-400 font-normal">
                          <i className="fa-light fa-bed  text-lg mr-8 ml-2 "></i>
                          <h2> {villa.aminities.rooms} غرف نوم</h2>
                        </div>
                        <div className="flex flex-row mt-4 col-span-1 text-lg text-center text-gray-400 font-normal">
                          <i className="fa-light fa-toilet  text-lg mr-8 ml-2 "></i>
                          <h2> {villa.aminities.bathrooms} حمامات</h2>
                        </div>
                      </div>
                      <hr />
                      <div
                        style={{ display: `${villa.hidePrice}` }}
                        className=" col-span-1 text-lg text-center text-gray-700 font-normal"
                      >
                        <h2>ابتداءً من {villa.price} شيكل لليوم</h2>
                      </div>
                      <div
                        style={{ display: `${villa.hideWeekendPrice}` }}
                        className=" text-gray-700 p-2 m-2 bg-blue-50  border-gray-200 border-2 border-solid rounded-xl"
                      >
                        <h2 className="text-md text-gray-500 leading-none w-full">
                          سعر نهاية الأسبوع و الأعياد {villa.priceWeekend}
                          <i className="fa-solid fa-shekel-sign text-xl mr-2 text-[10px] lg:text-[12px] "></i>
                        </h2>
                      </div>

                      {/* END VILLA TITLE AND PRICE */}
                    </div>
                  </Link>
                </div>
              ))}
          </React.Suspense>
        </div>
        <div className=" place-items-center text-center">
          <Link
            title="من نحن"
            rel="external allVillas dofollow"
            className="w-full cursor-pointer"
            to="/about"
            onClick={() => gaEventTracker("About us page")}
          >
            <h1 className="py-10 my-10">سجل في خدمة الفلل في أريحا</h1>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AllVillas;
