import React from "react";
import { useParams, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import logo from "./images/logo.webp";
import logoFull from "./images/logo-full.webp";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

export default function Navbar() {
  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);

  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  // Whenever the user explicitly chooses light mode
  localStorage.theme = "light";

  // Whenever the user explicitly chooses dark mode
  localStorage.theme = "dark";

  // Whenever the user explicitly chooses to respect the OS preference
  localStorage.removeItem("theme");

  return (
    <div className="flex justify-around items-center justify-items-center w-full font-semibold text-xl mb-0  md:text-2xl  ">
      <Link
        title="الصفحة الرئيسية"
        to="/"
        rel="canonical"
        onClick={() => gaEventTracker("Home")}
      >
        <div className="p-2 sm:space-x-10">
          <img
            alt="Ajar Logo"
            src={logo}
            className=" p-0 h-[60px]  sm:hidden "
          />
          <img
            alt="Ajar Logo"
            src={logoFull}
            className=" p-0 h-[60px] hidden sm:inline  focus:bg-white sm:p-1 sm:m-3 sm:rounded-lg "
          />
        </div>
      </Link>
      <Link
        to="allvillas"
        title="جميع الفلل"
        onClick={() => gaEventTracker("villas")}
      >
        <div
          id="allvillas"
          className="w-[90px] px-2 py-4 text-center md:border-r-0 border-gray-100 lg:border-yellow-400 hover:text-black hover:bg-gray-50 focus:bg-gray-50 target:bg-gray-50 md:w-[150px] md:rounded-full md:p-2 md:my-2"
        >
          <div className=" md:hidden  hover:-translate-y-2 hover:transition-transform ease-in-out">
            <i className="fa-light fa-house-building w-full"></i>
          </div>
          <div>الفلل</div>
        </div>
      </Link>
      <div className="border-r-2 md:border-r-yellow-200 w-[2px] p-0 m-0"></div>
      <Link
        to="restaurants"
        title="المطاعم"
        onClick={() => gaEventTracker("restaurants")}
      >
        <div className="w-[90px] px-2 py-4  text-center border-gray-100 lg:border-yellow-400 hover:text-black hover:bg-gray-50 md:w-[150px] md:rounded-full md:p-2 md:my-2">
          <div className=" md:hidden  hover:-translate-y-2 hover:transition-transform ease-in-out">
            <i className="fa-light fa-fork-knife w-full"></i>
          </div>
          <div>مطاعم</div>
        </div>
      </Link>
      <div className="border-r-2 md:border-r-yellow-200 w-[2px] p-0 m-0"></div>

      <Link
        to="deliveries"
        title="الخدمات"
        onClick={() => gaEventTracker("delivery")}
      >
        <div className="w-[90px] px-2 py-4  text-center hover:text-black hover:bg-gray-50 md:w-[150px] md:rounded-full md:p-2 md:my-2">
          <div className=" md:hidden  hover:-translate-y-2 hover:transition-transform ease-in-out">
            <i className="fa-light fa-moped w-full"></i>
          </div>
          <div> الخدمات</div>
        </div>
      </Link>
    </div>
  );
}
