import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import { useParams, Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import PropagateLoader from "react-spinners/PropagateLoader";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Deliveries = () => {
  const [deliveries, setDeliveries] = useState(null);
  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "delivery" ] | order(title desc)
        {...}      
        `
      )
      .then((data) => setDeliveries(data))
      .catch(console.error);
  }, [slug]);

  console.log(deliveries);

  if (!deliveries)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <React.Suspense
          fallback={
            <PropagateLoader color={"#eab305"} loading={loading} size={20} />
          }
        />
      </div>
    );

  return (
    <section className=" w-full mt-0 place-items-center object-center text-center scroll-smooth hover:scroll-auto lg:px-0 mx-auto">
      <Helmet>
        <title>أجار - Ajar | جميع كباتن التوصيل</title>
      </Helmet>
      <h3 className="w-full text-3xl text-gray-50 font-bold text-center py-10 bg-blue-700 ">
        جميع الخدمات المعروضة
      </h3>

      <div className="container mx-auto w-full place-items-center text-center place-content-center justify-items-center ">
        <div className="gap-5 p-5 text-center items-center place-items-center mx-auto bg-gray-50 "></div>

        <div className="grid grid-cols-9 flex-row gap-4 rounded-xl max-w-6xl place-content-center mx-auto ">
          {deliveries &&
            deliveries.map((delivery, index) => (
              <div
                className="col-span-9 md:col-span-3 bg-gray-50 rounded-xl shadow-xl std-border   hover:transform-gpu hover:ease-in-out hover:duration-500 hover:bg-white hover:shadow-md"
                key={delivery.title}
              >
                <Link
                  to={"/deliveries/" + delivery.slug.current}
                  key={delivery.slug.current}
                  onClick={() => gaEventTracker(`${delivery.slug.current}`)}
                >
                  <div
                    key={index}
                    className="flex flex-col items-center  justify-items-center"
                  >
                    <img
                      src={urlFor(delivery.picture)
                        .height(400)
                        .width(1000)
                        .url()}
                      alt={delivery.title}
                      className="overflow-hidden rounded-t-xl border-4 mb-4 std-border"
                    />

                    {/* VILLA TITLE AND PRICE */}
                    <div className="grid grid-cols-2 my-4">
                      <div className=" text-gray-700 place-items-center text-center col-span-2">
                        <h1 className="text-xl font-bold">{delivery.title}</h1>
                        <hr />
                      </div>
                    </div>
                    <hr />
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <div className=" place-items-center text-center">
          <Link
            title="من نحن"
            rel="external allDeliveries dofollow"
            className="w-full cursor-pointer"
            to="/about"
            onClick={() => gaEventTracker("About us page")}
          >
            <h1 className="py-10 my-10">سجل في خدمة التوصيل في أريحا</h1>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Deliveries;
