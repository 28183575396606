import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from "react-helmet";
import VillaMaps from "./VillaMap";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Delivery() {
  const [delivery, setDelivery] = useState(null);
  const [deliveryCompanies, setDeliveryCompanies] = useState(null);

  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{...}`, { slug })
      .then((data) => setDelivery(data[0]))
      .catch(console.error);
  }, [slug]);

  console.log(delivery);

  if (!delivery)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <i className="fa-solid fa-sync fa-spin text-4xl"></i>
      </div>
    );

  return (
    <section className="container w-full lg:px-0 px-5 lg:w-full mx-auto min-h-screen">
      <Helmet>
        <title>{delivery.title} | موقع اجار Ajar</title>
        <meta name="description" content={delivery.description} />
      </Helmet>

      {/* <a href="/allvillas">
        <div className="w-100 h-100 border-black bg-yellow-400 shadow-lg hover:shadow-sm rounded-full fixed bottom-20 right-10 scroll-my-10 will-change-auto hover:will-change-scroll  py-4 justify-center grid whitespace-nowrap ring-3 hover:ring-yellow-400">
          <i className="fa-light fa-angle-left text-2xl"></i>
        </div>
      </a> */}

      <div className="flex flex-wrap flex-col w-full text-center justify-center place-items-center">
        <div className="flex flex-col gap-4 text-right place-items-center m-4 p-4 bg-white opacity-100">
          <div className="rounded-full">
            <img
              src={urlFor(delivery.picture)}
              alt={delivery.title}
              className="rounded-full w-[300px]"
            />
          </div>
          <div>
            <h1 className="text-4xl text-slate-700 font-extrabold leading-none lg:text-[48px]">
              {delivery.title}
            </h1>
          </div>
          <div className="text-2xl text-gray-500 hover:text-gray-700 ">
            <BlockContent
              blocks={delivery.description}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
          </div>
        </div>
        <div className="text-yellow-500 w-full mb-10">
          <div className="flex flex-wrap w-full text-4xl md:text-[56px] gap-8 justify-center">
            <div style={{ display: `${delivery.showFacebook}` }}>
              <a
                href={"https://www.facebook.com/" + delivery.facebook}
                target="_blank"
                rel="external facebook dofollow"
                title="فيسبوك"
                onClick={() => gaEventTracker(`${delivery.title} Facebook`)}
              >
                <i className="fa-brands fa-facebook ml-2 w-full hover:text-blue-700"></i>
              </a>
            </div>
            <div style={{ display: `${delivery.showInstagram}` }}>
              <a
                href={"https://www.instagram.com/" + delivery.instagram}
                target="_blank"
                rel="external instagram dofollow"
                title="انستغرام"
                onClick={() => gaEventTracker(`${delivery.title} Instagram`)}
              >
                <i className="fa-brands fa-instagram ml-2 w-full hover:text-pink-700"></i>
              </a>
            </div>
            <div style={{ display: `${delivery.showTiktok}` }}>
              <a
                href={"https://vt.tiktok.com/" + delivery.tiktok}
                target="_blank"
                rel="external tiktok dofollow"
                title="تيكتوك"
                onClick={() => gaEventTracker(`${delivery.title} Tiktok`)}
              >
                <i className="fa-brands fa-tiktok ml-2 w-full hover:text-black"></i>
              </a>
            </div>
            <div style={{ display: `${delivery.showSnapchat}` }}>
              <a
                href={"https://www.snapchat.com/add/" + delivery.snapchat}
                target="_blank"
                rel="external snapchat dofollow"
                title="سنابشات"
                onClick={() => gaEventTracker(`${delivery.title} Snapchat`)}
              >
                <i className="fa-brands fa-snapchat ml-2 w-full hover:text-black"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* END OF MAIN HEADER AND INFO */}

      <section
        name="التواصل"
        className="flex flex-row justify-around bg-white lg:gap-4 lg:max-w-6xl lg:justify-evenly justify-items-center place-content-center mx-auto"
      >
        <a
          href={"tel:" + delivery.mobile}
          target="_blank"
          rel="external mobile dofollow"
          title="الهاتف"
          className="w-full"
          style={{ display: `${delivery.showCall}` }}
          onClick={() => gaEventTracker(`Call ${delivery.title} owner`)}
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-rose-700 hover:bg-rose-600 
            focus:shadow-gray-200"
          >
            <i className="fa-light fa-mobile-signal-out ml-2 w-full"></i> هاتف
          </div>
        </a>

        <a
          title="واتساب"
          id="wa"
          rel="external whatsapp dofollow"
          className="w-full"
          style={{ display: `${delivery.showWhatsapp}` }}
          onClick={() =>
            gaEventTracker(`Whatsapp ${delivery.slug.current} owner`)
          }
          href={
            "https://wa.me/972" +
            delivery.mobile +
            "?text=مرحبا، أنا بتواصل معك عن طريق موقع " +
            " *Ajar - أجار* للفلل في أريحا" +
            "%0a و حابب أعمل طلبية " +
            "%0a شكرا"
          }
          target="_blank"
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-green-700 hover:bg-green-600 
            focus:shadow-gray-200"
          >
            <i className="fa-brands fa-whatsapp ml-2 w-full"></i> واتساب
          </div>
        </a>

        <a
          title="محادثة"
          id="wa"
          rel="fa-light comment-dots dofollow"
          className="w-full"
          style={{ display: `${delivery.showChat}` }}
          onClick={() =>
            gaEventTracker(`Chat with ${delivery.slug.current} owner`)
          }
          href={
            "https://tawk.to/chat/" +
            delivery.propertyId +
            "/" +
            delivery.widgetId
          }
          target="_blank"
        >
          <div
            className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-yellow-600 hover:bg-yellow-500 
            focus:shadow-gray-200"
          >
            <i className="fa-light fa-comment-dots ml-2 w-full"></i> محادثة
          </div>
        </a>
      </section>

      <div className=" place-items-center text-center">
        <Link
          title="من نحن"
          rel="external allVillas dofollow"
          className="w-full cursor-pointer"
          to="/about"
          onClick={() => gaEventTracker("About us page")}
        >
          <h1 className="py-10 my-10">سجل في خدمة الفلل في أريحا</h1>
        </Link>
      </div>
    </section>
  );
}
