import React from "react";
import { Link } from "react-router-dom";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import ReactGA from "react-ga";

const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker();

  return (
    <div className="flex flex-col col-span-9 w-full rounded-b-2xl bg-gray-100">
      <div className=" grid grid-flow-row w-full pb-28 row-auto mx-auto text-center justify-items-center
                      md:grid-flow-col md:pb-0">
        <div className="flex p-5 gap-5 justify-items-center items-center text-center text-4xl">
          <div>
            <a
              href="https://www.facebook.com/ajar.villas"
              target="_blank"
              rel="noreferrer"
              onClick={() => gaEventTracker("Ajar Facebook")}
            >
              <i className="fa-brands fa-facebook ml-2"></i>
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/ajar.villas"
              target="_blank"
              rel="noreferrer"
              onClick={() => gaEventTracker("Ajar Instagram")}
            >
              <i className="fa-brands fa-instagram ml-2"></i>
            </a>
          </div>
          <div>
            <a
              href={
                "https://wa.me/972546708508" +
                "?text=مرحبا، أنا بتواصل معك عن طريق موقع " +
                " *Ajar - أجار للفلل في أريحا*" +
                "%0a شكرا"
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => gaEventTracker("Ajar Whatsapp")}
            >
              <i className="fa-brands fa-whatsapp ml-2"></i>
            </a>
          </div>
        </div>
        <div className="flex p-5 gap-5 justify-items-center items-center text-center text-lg">
          <p>
            © جميع الحقوق محفوظة
            <a
              href="https://www.burhan.studio"
              target="_blank"
              className="text-yellow-500"
              onClick={() => gaEventTracker(`Visit Burhan Portfolio`)}
            >
              &nbsp; Burhan Khatib - برهان خطيب
            </a>
          </p>
        </div>
        <div className="flex p-5 gap-5 justify-items-center items-center text-center text-lg">
          <Link
            to="/about"
            className=""
            onClick={() => gaEventTracker("About us page")}
          >
            تعرف علينا
          </Link>
        </div>
      
      </div>
    </div>
  );
};

export default Footer;
