import React from "react";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import Navbar from "./Navbar";

export default function Header() {
  const gaEventTracker = useAnalyticsEventTracker();

  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  // Whenever the user explicitly chooses light mode
  localStorage.theme = "light";

  // Whenever the user explicitly chooses dark mode
  localStorage.theme = "dark";

  // Whenever the user explicitly chooses to respect the OS preference
  localStorage.removeItem("theme");

  return (
    <div
      className="flex flex-col col-span-9 fixed mt-96 justify-evenly w-full std-border bottom-0 right-0 items-center place-items-center shadow-xl bg-white drop-shadow-xl shadow-gray-700 
    md:shadow-xl md:top-0 md:bottom-auto md:bg-yellow-500 md:mt-0 md:relative
    order-last md:order-first md:rounded-t-2xl"
    >
      <Navbar />
    </div>
  );
}
