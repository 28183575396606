import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from "react-helmet";
import VillaMaps from "./VillaMap";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Restaurant() {
  const [restaurant, setRestaurant] = useState(null);
  const [deliveries, setDeliveries] = useState(null);

  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{...}`, { slug })
      .then((data) => setRestaurant(data[0]))
      .catch(console.error);
  }, [slug]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "delivery" ] [0..4]{...}
                `
      )
      .then((data) => setDeliveries(data))
      .catch(console.error);
  }, []);

  console.log(restaurant);
  console.log(deliveries);

  if (!restaurant)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <i className="fa-solid fa-sync fa-spin text-4xl"></i>
      </div>
    );

  return (
    <section className=" w-full lg:px-0 mx-auto ">
      <Helmet>
        <title>{restaurant.title} | موقع اجار Ajar</title>
        <meta name="description" content={restaurant.description} />
      </Helmet>

      {/* <a href="/allvillas">
        <div className="w-100 h-100 border-black bg-yellow-400 shadow-lg hover:shadow-sm rounded-full fixed bottom-20 right-10 scroll-my-10 will-change-auto hover:will-change-scroll  py-4 justify-center grid whitespace-nowrap ring-3 hover:ring-yellow-400">
          <i className="fa-light fa-angle-left text-2xl"></i>
        </div>
      </a> */}

      <div className=" w-full overflow-hidden  rounded-b-2xl place-items-center object-center">
        <img
          src={urlFor(restaurant.mainImage)}
          alt={restaurant.title}
          className="w-screen"
        />
      </div>

      <div className="container w-full mx-auto">
        <div className="flex flex-wrap flex-col w-full md:mt-14 text-center justify-center place-items-center">
          <div className="flex flex-wrap flex-col text-center place-items-center  bg-white md:flex-cols md:w-full  md:place-content-center md:place-items-center md:mx-auto md:rounded-full md:justify-center ">
            <div className=" ">
              <img
                src={urlFor(restaurant.restaurantLogo)}
                alt={restaurant.title}
                className="h-[100px] md:-mt-[50px]"
              />
            </div>
            <div className="mb-10">
              <h1 className="text-4xl text-slate-700 font-extrabold leading-none lg:text-[48px]">
                {restaurant.title}
              </h1>
            </div>

            <div className="text-2xl text-gray-500 hover:text-gray-700 ">
              <BlockContent
                blocks={restaurant.body}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
              />
            </div>
            <div>
              <p className=" mt-5 text-gray-500 hover:text-gray-700">
                <span className="font-bold text-2xl text-gray-500">
                  <i className="fa-light fa-map-location-dot ml-4"></i>العنوان :{" "}
                </span>
                {restaurant.location_text}
              </p>
            </div>
          </div>
          <div className="text-yellow-500 w-full mb-10">
            <div className="flex flex-wrap w-full text-4xl md:text-[56px] gap-8 justify-center">
              <div style={{ display: `${restaurant.showFacebook}` }}>
                <a
                  href={"https://www.facebook.com/" + restaurant.facebook}
                  target="_blank"
                  rel="external facebook dofollow"
                  title="فيسبوك"
                  onClick={() => gaEventTracker(`${restaurant.title} Facebook`)}
                >
                  <i className="fa-brands fa-facebook ml-2 w-full hover:text-blue-700"></i>
                </a>
              </div>
              <div style={{ display: `${restaurant.showInstagram}` }}>
                <a
                  href={"https://www.instagram.com/" + restaurant.instagram}
                  target="_blank"
                  rel="external instagram dofollow"
                  title="انستغرام"
                  onClick={() =>
                    gaEventTracker(`${restaurant.title} Instagram`)
                  }
                >
                  <i className="fa-brands fa-instagram ml-2 w-full hover:text-pink-700"></i>
                </a>
              </div>
              <div style={{ display: `${restaurant.showTiktok}` }}>
                <a
                  href={"https://vt.tiktok.com/" + restaurant.tiktok}
                  target="_blank"
                  rel="external tiktok dofollow"
                  title="تيكتوك"
                  onClick={() => gaEventTracker(`${restaurant.title} Tiktok`)}
                >
                  <i className="fa-brands fa-tiktok ml-2 w-full hover:text-black"></i>
                </a>
              </div>
              <div style={{ display: `${restaurant.showSnapchat}` }}>
                <a
                  href={"https://www.snapchat.com/add/" + restaurant.snapchat}
                  target="_blank"
                  rel="external snapchat dofollow"
                  title="سنابشات"
                  onClick={() => gaEventTracker(`${restaurant.title} Snapchat`)}
                >
                  <i className="fa-brands fa-snapchat ml-2 w-full hover:text-black"></i>
                </a>
              </div>
              <div style={{ display: `${restaurant.showGoogleMaps}` }}>
                <a
                  href={"https://goo.gl/maps/" + restaurant.googleMaps}
                  target="_blank"
                  rel="external google maps dofollow"
                  title="خراذط جوجل"
                  onClick={() => gaEventTracker(`${restaurant.title} Snapchat`)}
                >
                  <i className="fa-brands fa-google ml-2 w-full hover:text-black"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* END OF MAIN HEADER AND INFO */}

        <section
          name="التواصل"
          className="flex flex-row justify-around bg-white lg:gap-4 lg:max-w-6xl lg:justify-evenly justify-items-center place-content-center mx-auto"
        >
          <a
            href={"tel:" + restaurant.telephone}
            target="_blank"
            rel="external mobile dofollow"
            title="الهاتف"
            className="w-full"
            style={{ display: `${restaurant.showCall}` }}
            onClick={() =>
              gaEventTracker(`Call ${restaurant.slug.current} owner`)
            }
          >
            <div
              className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-rose-700 hover:bg-rose-600 
            focus:shadow-gray-200"
            >
              <i className="fa-light fa-mobile-signal-out ml-2 w-full"></i> هاتف
            </div>
          </a>

          <a
            title={`موقع ${restaurant.title}`}
            rel="external location dofollow"
            className="w-full"
            style={{ display: `${restaurant.showMap}` }}
            onClick={() =>
              gaEventTracker(`Navigate to ${restaurant.slug.current}`)
            }
            href={
              "https://maps.google.com/?q=" +
              restaurant.location.lat +
              "," +
              restaurant.location.lng
            }
            target="_blank"
          >
            <div
              className=" 
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-blue-700 hover:bg-blue-600 
            focus:shadow-gray-200"
            >
              <i className="fa-light fa-map-location-dot ml-2 w-full"></i>{" "}
              الموقع
            </div>
          </a>

          <a
            title="واتساب"
            id="wa"
            rel="external whatsapp dofollow"
            className="w-full"
            style={{ display: `${restaurant.showWhatsapp}` }}
            onClick={() =>
              gaEventTracker(`Whatsapp ${restaurant.slug.current} owner`)
            }
            href={
              "https://wa.me/972" +
              restaurant.mobile +
              "?text=مرحبا، أنا بتواصل معك عن طريق موقع " +
              " *Ajar - أجار* للفلل في أريحا" +
              "%0a و حابب أستفسر عن *" +
              restaurant.title +
              "* %0a https://ajar.villas/villas/" +
              restaurant.slug.current +
              "%0a شكرا"
            }
            target="_blank"
          >
            <div
              className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-green-700 hover:bg-green-600 
            focus:shadow-gray-200"
            >
              <i className="fa-brands fa-whatsapp ml-2 w-full"></i> واتساب
            </div>
          </a>

          <a
            title="محادثة"
            id="wa"
            rel="fa-light comment-dots dofollow"
            className="w-full"
            style={{ display: `${restaurant.showChat}` }}
            onClick={() =>
              gaEventTracker(`Chat with ${restaurant.slug.current} owner`)
            }
            href={
              "https://tawk.to/chat/" +
              restaurant.propertyId +
              "/" +
              restaurant.widgetId
            }
            target="_blank"
          >
            <div
              className="
            lg:w-full text-center mx-2 text-xl lg:text-4xl ring text-yellow-500 rounded-md shadow-md p-4 lg:p-10
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-yellow-600 hover:bg-yellow-500 
            focus:shadow-gray-200"
            >
              <i className="fa-light fa-comment-dots ml-2 w-full"></i> محادثة
            </div>
          </a>
        </section>

        <section
          name="الخريطة"
          className="text-center py-20 place-items-center text-gray-500 hover:text-gray-700"
        >
          <div className="text-4xl">
            <i className="fa-light fa-map w-full"></i>
          </div>
          <h2 className="text-xl font-bold lg:text-2xl rtl">
            خريطة {restaurant.title}
          </h2>
          <p className="pb-10">{restaurant.title} على خرائط Google Maps</p>

          <VillaMaps />
        </section>

        <section
          name="الصور"
          className="overflow-hidden py-20 text-gray-500 hover:text-gray-700 text-center "
          style={{ display: `${restaurant.showOffers}` }}
        >
          <div className="text-4xl">
            <i className="fa-light fa-images w-full"></i>
          </div>
          <h2 className="text-xl font-bold lg:text-2xl rtl">
            صور و عروض {restaurant.title}
          </h2>
          <p className="">
            عدد الصور والعروض : {restaurant.offers.images.length}
          </p>

          <div className="container px-0 md:px-5 py-2 mx-auto lg:px-10">
            <div className="flex flex-wrap -m-1 md:-m-2">
              {/* {!restaurant.offers.images.map(() => {
            return (
              <div>NO CONTENT</div>
            )})} */}
              {restaurant.offers.images.map((index) => {
                return (
                  <div className="flex flex-wrap w-full md:w-1/4">
                    <div className="w-full p-1 md:p-2">
                      <a
                        href={urlFor(
                          restaurant.offers.images + index.asset._ref
                        )}
                        target="_blank"
                      >
                        <img
                          src={urlFor(
                            restaurant.offers.images + index.asset._ref
                          )}
                          alt={restaurant.title}
                          className="block object-cover object-center w-full h-full rounded-lg"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* <BlockContent
          blocks={restaurant.gallery}
          projectId={sanityClient.clientConfig.projectId}
          dataset={sanityClient.clientConfig.dataset}
          className="grid grid-cols-1 lg:grid-cols-3 items-stretch w-full gap-4 object-center"
        /> */}

        <section
          name="المنيو"
          className="overflow-hidden mb-20 text-gray-500 hover:text-gray-700 text-center "
        >
          <div className="text-4xl">
            <i className="fa-light fa-fork-knife w-full"></i>
          </div>
          <h2 className="text-xl font-bold lg:text-2xl rtl">
            منيو الطعام من {restaurant.title}
          </h2>
          <hr className="pb-4" />

          <div className="container px-0 md:px-5 py-2 mx-auto lg:px-10">
            <div className="flex flex-wrap -m-1 md:-m-2">
              {restaurant.menu.images.map((index) => {
                return (
                  <div className="flex flex-wrap w-full md:w-1/2">
                    <div className="w-full p-1 md:p-2">
                      <a
                        href={urlFor(restaurant.menu.images + index.asset._ref)}
                        target="_blank"
                        onClick={() =>
                          gaEventTracker(`Menu Click ${restaurant.title}`)
                        }
                      >
                        <img
                          src={urlFor(
                            restaurant.menu.images + index.asset._ref
                          )}
                          alt={restaurant.menu.title}
                          className="block object-cover object-center w-full h-full rounded-lg"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section
          name="كابتن التوصيل"
          className="overflow-hidden mb-20 text-gray-500 hover:text-gray-700 text-center "
        >
          <div className="text-4xl">
            <i className="fa-light fa-moped w-full"></i>
          </div>
          <h2 className="text-xl font-bold lg:text-2xl rtl">كابتن التوصيل</h2>
          <hr className="pb-4" />

          <div className="flex flex-wrap flex-row gap-4 mx-4 rounded-xl  ">
            {deliveries &&
              deliveries.map((delivery, index) => (
                <div
                  className="lg:w-[340px] bg-gray-50 rounded-xl shadow-xl std-border   hover:transform-gpu hover:ease-in-out hover:duration-500 hover:bg-white hover:shadow-md"
                  key={delivery.title}
                >
                  <div
                    key={index}
                    className="flex flex-col items-center  justify-items-center"
                  >
                    <Link
                      to={"/deliveries/" + delivery.slug.current}
                      key={delivery.slug.current}
                      onClick={() => gaEventTracker(`${delivery.slug.current}`)}
                    >
                      <img
                        src={urlFor(delivery.picture)
                          .height(400)
                          .width(1000)
                          .url()}
                        alt={delivery.title}
                        className="overflow-hidden rounded-t-xl border-4 mb-2 std-border"
                      />
                    </Link>

                    <div className="grid grid-cols-2 my-4">
                      <div className=" text-gray-700 place-items-center text-center col-span-2">
                        <h1 className="text-xl font-bold mb-4">
                          {delivery.title}
                        </h1>

                        <hr />
                        <section
                          name="التواصل"
                          className="flex flex-row justify-around opacity-95 bg-white lg:gap-4 lg:justify-evenly justify-items-center"
                        >
                          <a
                            href={"tel:" + delivery.mobile}
                            target="_blank"
                            rel="external mobile dofollow"
                            title="الهاتف"
                            className="w-full"
                            style={{ display: `${delivery.showCall}` }}
                            onClick={() =>
                              gaEventTracker(
                                `Call ${delivery.slug.current} owner`
                              )
                            }
                          >
                            <div
                              className="
            lg:w-full text-center text-xl ring text-yellow-500 rounded-md shadow-md p-4
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-rose-700 hover:bg-rose-600 
            focus:shadow-gray-200"
                            >
                              <i className="fa-light fa-mobile-signal-out ml-2 w-full"></i>{" "}
                              هاتف
                            </div>
                          </a>

                          <a
                            title="واتساب"
                            id="wa"
                            rel="external whatsapp dofollow"
                            className="w-full"
                            style={{ display: `${delivery.showWhatsapp}` }}
                            onClick={() =>
                              gaEventTracker(
                                `Whatsapp ${delivery.slug.current} owner`
                              )
                            }
                            href={
                              "https://wa.me/972" +
                              delivery.mobile +
                              "?text=مرحبا، أنا بتواصل معك عن طريق موقع " +
                              " *Ajar - أجار* للفلل في أريحا" +
                              "%0a و حابب أعمل طلبية من *" +
                              restaurant.title +
                              "* %0a https://ajar.villas/restaurants/" +
                              restaurant.slug.current +
                              "%0a شكرا"
                            }
                            target="_blank"
                          >
                            <div
                              className="
                            lg:w-full text-center text-xl ring text-yellow-500 rounded-md shadow-md p-4
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-green-700 hover:bg-green-600 
            focus:shadow-gray-200"
                            >
                              <i className="fa-brands fa-whatsapp ml-2 w-full"></i>{" "}
                              واتساب
                            </div>
                          </a>

                          <a
                            title="محادثة"
                            id="wa"
                            rel="fa-light comment-dots dofollow"
                            className="w-full"
                            style={{ display: `${delivery.showChat}` }}
                            onClick={() =>
                              gaEventTracker(
                                `Chat with ${delivery.slug.current} owner`
                              )
                            }
                            href={
                              "https://tawk.to/chat/" +
                              delivery.propertyId +
                              "/" +
                              delivery.widgetId
                            }
                            target="_blank"
                          >
                            <div
                              className="
                            lg:w-full text-center text-xl ring text-yellow-500 rounded-md shadow-md p-4
            bg-white ring-gray-200  shadow-gray-700  
            hover:text-white hover:ring-yellow-600 hover:bg-yellow-500 
            focus:shadow-gray-200"
                            >
                              <i className="fa-light fa-comment-dots ml-2 w-full"></i>{" "}
                              محادثة
                            </div>
                          </a>
                        </section>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              ))}
          </div>
        </section>

        <div className=" place-items-center text-center">
          <Link
            title="من نحن"
            rel="external allVillas dofollow"
            className="w-full cursor-pointer"
            to="/about"
            onClick={() => gaEventTracker("About us page")}
          >
            <h1 className="py-10 my-10">سجل في خدمة الفلل في أريحا</h1>
          </Link>
        </div>
      </div>
      {/* end of container */}
    </section>
  );
}
