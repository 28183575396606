import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import "babel-polyfill";
import "tw-elements";
import Header from "./components/Header";
import Footer from "./components/Footer";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <div className="grid max-w-7xl mx-auto grid-cols-9 md:rounded-2xl md:mt-3 md:mb-10 md:shadow-2xl md:shadow-slate-700">
      <Header />
      <App />
      <Footer />
    </div>
  </BrowserRouter>
  // document.getElementById("root")
);
