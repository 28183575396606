import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Restaurants from "./components/Restaurants";
import Deliveries from "./components/Deliveries";
import AllVillas from "./components/AllVillas";
import Villa from "./components/Villa";
import ReactGA from "react-ga";
import Restaurant from "./components/Restaurant";
import Delivery from "./components/Delivery";
import ScrollToTop from "./helpers/ScrollToTop";


const TRACKING_ID = "UA-120222790-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() { 
  // const navigate = useNavigate();
  return (
    <div className="flex flex-col col-span-9 w-full">
       {/* <button className="z-100 fixed bottom-[100px] right-10" onClick={() => navigate(-1)}><i className="fa-solid fa-circle-chevron-left text-gray-700 bg-white rounded-full h-10 lg:h-20"></i></button> */}
     
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home title="الصفحة الرئيسية" />} />
        <Route path="about" element={<About title="من نحن" />} />
        <Route path="contact" element={<Contact title="إتصل بنا" />} />
        <Route path="allvillas" element={<AllVillas title="جميع الفلل" />} />
        <Route path="restaurants" element={<Restaurants title="المطاعم" />} />
        <Route path="deliveries" element={<Deliveries title="التوصيل" />} />
        <Route path="*" element={<Home />} />
        <Route path="/villas/:slug" element={<Villa />} />
        <Route path="/restaurants/:slug" element={<Restaurant />} />
        <Route path="/deliveries/:slug" element={<Delivery />} />
      </Routes>
      

    </div>
  );
}

export default App;
