import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import GoogleMapReact from 'google-map-react';
import { useParams } from "react-router-dom";

const VillaLocation = ({ text }) => <div className="flex w-max align-middle place-content-center place-items-center"><i className="fa-solid fa-location-dot text-2xl text-red-600"></i><div className=" py-2 px-4 text-2xl font-bold rounded-2xl text-clip bg-yellow-400 bg-opacity-90">{text}</div></div>;




export default function VillaMaps() {
  const [villaMap, setVillaMap] = useState(null);
  const { slug } = useParams();
  const mapProps = {
    center: {
      lat: 31.856410,
      lng: 35.460946
    },
    zoom: 13,
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{
        _id,
        title,
        location,
      }`, { slug })
      .then((data) => setVillaMap(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!villaMap)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <i className="fa-solid fa-sync fa-spin text-4xl"></i>
      </div>
    );

  return (
     
          <div key={villaMap._id} style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCc03D4O4_VnUtOsn9nuOovfrxYaz27YOI" }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
      >
        
          <VillaLocation className="bg-red-500 p-10 w-100 h-100"
          lat={villaMap.location.lat}
          lng={villaMap.location.lng}
          text={villaMap.title}
        />
        
      </GoogleMapReact>
    </div>
      
   
  );
};
