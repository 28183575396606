import React, { useState, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import aboutPicture from "./images/homepage-banner.webp";
import PropagateLoader from "react-spinners/PropagateLoader";
import BannersSkeleton from "./BannersSkeleton";
import ScrollToTop from "../helpers/ScrollToTop";

const Banners = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import("./Banners"));
    }, 30);
  });
});

const LatestSixVillas = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import("./LatestSixVillas"));
    }, 30);
  });
});

const LatestSixRestaurants = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import("./LatestSixRestaurants"));
    }, 30);
  });
});

const LatestSixDeliveries = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import("./LatestSixDeliveries"));
    }, 30);
  });
});

// import foodImage from "../images/protein/001.jpg";

const Home = () => {
  const { slug } = useParams();
  const gaEventTracker = useAnalyticsEventTracker(slug);
  let [loading] = useState(true);

  return (
    <div className="flex flex-col col-span-9 w-full items-center text-center bg-white dark:bg-gray-800 sweet-loading">
      <ScrollToTop />
      <Helmet>
        <title>موقع اجار لتاجير الفلل في اريحا</title>
        <meta
          name="description"
          content="موقع أجار هو موقع مختص لأجار الفلل في أريحا. يتوفر أيضاً قسم كامل يشمل مطاعم أريحا و قسم اخر لخدمة التوصيل"
        />
      </Helmet>
      {/* <Suspense fallback={<BannersSkeleton />}>
        <Header />
      </Suspense> */}

      <Suspense fallback={<BannersSkeleton />}>
        <Banners />
      </Suspense>

      <section className="bg-yellow-500 w-full">
        <div className="w-full m-auto flex flex-wrap-reverse place-items-center justify-center md:flex md:flex-cols">
          <div className="w-full place-items-center justify-center text-center p-10 md:px-10 md:w-1/2 my-4">
            <h1 className="text-gray-700 text-4xl font-bold mb-2">
              أهلا بكم في موقع أجار
            </h1>
            <h2 className="text-gray-700 text-2xl font-bold mb-5">
              تواصل مع المالك مباشرة
            </h2>
            <p>
              تتيح منصة اجار طرق متعددة للتواصل مع المالك سواء عن طريق الهاتف أو
              الواتساب أو تطبيق المحادثة الخاص بالموقع بكل خصوصية.
            </p>
            <p>
              تتوفر اسعار متعددة للفلل و مناسبة للجميع بكل شفافية و من دون اي
              مبالغ مخفية قذ تفاجىء المستأجر.&nbsp;
            </p>
            <p>
              يتوفر ايضا في موقع اجار صفحات للمطاعم و خدمات التوصيل و الأراجيل
              للفيلا دون الحاجة لمغادرة الأجواء العائلية المميزة.
            </p>

            <div className=" flex w-200 mt-4 mx-auto  text-center">
              <div className="w-full">
                <a
                  title="للانضمام للموقع"
                  rel="external JoinAjar dofollow"
                  className="w-full cursor-pointer"
                  href="https://tawk.to/chat/62ce8f377b967b1179994d81/1g7rhbbdq"
                  onClick={() => gaEventTracker("About us page")}
                >
                  <button className="text-md cursor-pointer text-gray-700 font-bold rounded-full hover:text-white my-2 md:my-5">
                    <i className="fa-regular fa-comment-dots text-4xl "></i>
                    {/* انضم لاجار اليوم */}
                  </button>
                </a>
              </div>

              <div className="w-full">
                <a
                  title="صفحة أجار على الفيسبوك"
                  rel="external FacebookInstagram dofollow"
                  className="w-full cursor-pointer"
                  href="https://www.facebook.com/ajar.villas"
                  onClick={() => gaEventTracker("Ajar Facebook")}
                >
                  <button className="text-md cursor-pointer text-gray-700 font-bold rounded-full hover:text-blue-700 my-2 md:my-5">
                    <i className="fa-brands fa-facebook text-4xl "></i>
                    {/* اجار على الفيسبوك */}
                  </button>
                </a>
              </div>

              <div className="w-full">
               
                <a
                  title="صفحة أجار على الانستغرام"
                  rel="external AjarInstagram dofollow"
                  className="w-full cursor-pointer"
                  href="https://www.instagram.com/ajar.villas"
                  onClick={() => gaEventTracker("Ajar Instagram")}
                >
                  <button className="text-md cursor-pointer text-gray-700 font-bold rounded-full hover:text-pink-700 my-2 md:my-5">
                    <i className="fa-brands fa-instagram text-4xl "></i>
                    {/* <span>اجار على الانستغرام</span> */}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            className="place-items-center text-center md:w-1/2 h-[500px]"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "900px",
              backgroundPosition: "top center",
              backgroundImage: `url(${aboutPicture})`,
            }}
          >
            {/* <img className="w-[300px]" src={logo} /> */}
          </div>
        </div>
      </section>

      <Suspense
        fallback={
          <PropagateLoader
            color={"#eab305"}
            loading={loading}
            size={20}
            className="min-h-100 justify-items-center place-items-center text-center"
          />
        }
      >
        <LatestSixVillas />
      </Suspense>

      <Suspense
        fallback={
          <PropagateLoader
            color={"#eab305"}
            loading={loading}
            size={20}
            className="min-h-100 justify-items-center place-items-center text-center"
          />
        }
      >
        <LatestSixRestaurants />
      </Suspense>

      <Suspense
        fallback={
          <PropagateLoader
            color={"#eab305"}
            loading={loading}
            size={20}
            className="min-h-100 justify-items-center place-items-center text-center"
          />
        }
      >
        <LatestSixDeliveries />
      </Suspense>

      <div className=" place-items-center text-center">
        <a
          title="من نحن"
          rel="external allVillas dofollow"
          className="w-full cursor-pointer"
          href="https://tawk.to/chat/62ce8f377b967b1179994d81/1g7rhbbdq"
          onClick={() => gaEventTracker("About us page")}
        >
          <button className=" text-2xl w-250 bg-yellow-500 text-white font-bold rounded-xl  hover:bg-yellow-400 m-10 py-4 px-8 ">
            <i className="fa-regular fa-comment-dots ml-2 text-4xl"></i>
            للإنضمام للموقع
          </button>
        </a>
      </div>
      {/* <Suspense fallback={<BannersSkeleton />}>
        <Footer />
      </Suspense> */}
    </div>
  );
};

export default Home;
