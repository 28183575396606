import React, { useRef } from "react";
import aboutPicture from "./images/about-banner.webp";
// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Helmet } from "react-helmet";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import ReactGA from "react-ga4";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function About() {
  const gaEventTracker = useAnalyticsEventTracker();
  const tawkMessengerRef = useRef();
  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };
  const onLoad = () => {
    console.log("onLoad works!");
  };

  return (
    <section className="grid items-center place-items-center text-center bg-white dark:bg-slate-800 ">
      <Helmet>
        <title>أجار - Ajar | من نحن</title>
        <meta name="description" content="خدمة التوصيل من موقع أجار في أريحا" />
      </Helmet>
      {/* <TawkMessengerReact
        propertyId="62ce8f377b967b1179994d81"
        widgetId="1g7rhbbdq"
        onLoad={onLoad}
      /> */}

      <div className=" place-items-center text-center">
        <img alt="تعرف علينا و من نحن" src={aboutPicture} />
      </div>
      <a
          title="من نحن"
          rel="external allVillas dofollow"
          className="w-full cursor-pointer"
         href="https://tawk.to/chat/62ce8f377b967b1179994d81/1g7rhbbdq"
         onClick={() => gaEventTracker("About us page")}
        >
      <button className=" text-2xl w-250 bg-yellow-500 text-white font-bold rounded-xl drop-shadow-xl hover:drop-shadow-sm m-10 py-4 px-8 ">
      <i className="fa-regular fa-comment-dots ml-2 text-4xl"></i>
        تحدث مع برهان
        </button>
        </a>
    </section>
  );
}

export default About;
