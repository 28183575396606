import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import PropagateLoader from "react-spinners/PropagateLoader";

const TRACKING_ID = "322257631";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const AllRestaurants = () => {
  const [restaurants, setRestaurants] = useState(null);
  const gaEventTracker = useAnalyticsEventTracker(null);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "restaurant" ] | order(title desc)
        {...}      
        `
      )
      .then((data) => setRestaurants(data))
      .catch(console.error);
  }, []);

  console.log(restaurants);

  if (!restaurants)
    return (
      <div className="flex flex-grid gap-4 justify-items-center justify-center place-content-center py-10 h-screen">
        <React.Suspense
          fallback={
            <PropagateLoader color={"#eab305"} loading={loading} size={20} />
          }
        />
      </div>
    );

  return (
    <section className=" w-full mt-0 place-items-center object-center text-center scroll-smooth hover:scroll-auto lg:px-0 mx-auto">
      <Helmet>
        <title>أجار - Ajar | جميع المطاعم</title>
      </Helmet>
      <h3 className="w-full text-3xl text-gray-50 font-bold text-center py-10 bg-red-700 ">
        جميع المطاعم المعروضة
      </h3>
      {/* <div className="flex lg:flex-row flex-col my-10 justify-center">
        <div className="flex items-center lg:mt-0 mt-5 gap-3 lg:flex-row flex-col">
          <input
            type="text"
            className="w-full lg:w-80 p-2 border-2 border-gray-100 rounded focus:outline-none"
          />
          <button
            style={{ backgroundColor: "#FE043C" }}
            className="rounded w-full lg:w-auto px-10 py-3 text-white"
          >
            Search
          </button>
        </div>
      </div>
      <hr className="my-10" /> */}
      <div className="container mx-auto w-full place-items-center text-center place-content-center justify-items-center ">
        <div className="gap-5 p-5 text-center items-center place-items-center mx-auto bg-gray-50 "></div>

        <div className="grid grid-cols-9 flex-row gap-4 rounded-xl max-w-6xl place-content-center mx-auto ">
          <React.Suspense
            fallback={
              <PropagateLoader color={"#eab305"} loading={loading} size={20} />
            }
          >
            {restaurants &&
              restaurants
                // .filter((villa) => {
                //   if (roomNumber == "" )
                //   return restaurants;
                //   else if (restaurant.rooms.includes(roomNumber)) {
                //     const roomNumberFiltered =  roomNumber;

                //     console.log(roomNumberFiltered)
                //     return restaurants;
                //   }
                // })
                .map((restaurant, index) => (
                  <div
                    className="col-span-9 md:col-span-3 bg-gray-50 rounded-xl shadow-xl std-border   hover:transform-gpu hover:ease-in-out hover:duration-500 hover:bg-white hover:shadow-md"
                    key={restaurant.title}
                  >
                    <Link
                      to={"/restaurants/" + restaurant.slug.current}
                      key={restaurant.slug.current}
                      onClick={() =>
                        gaEventTracker(`${restaurant.slug.current}`)
                      }
                    >
                      <div
                        key={index}
                        className="flex flex-col items-center  justify-items-center"
                      >
                        <img
                          src={urlFor(restaurant.restaurantLogo)
                            .height(400)
                            .width(1000)
                            .url()}
                          alt={restaurant.title}
                          className="overflow-hidden rounded-t-xl border-4 mb-4 std-border"
                        />

                        {/* VILLA TITLE AND PRICE */}
                        <div className="grid grid-cols-2 my-4">
                          <div className=" text-gray-700 place-items-center text-center col-span-2">
                            <h1 className="text-xl font-bold">
                              {restaurant.title}
                            </h1>
                            <hr />
                          </div>
                        </div>
                        <hr />
                        <div className="pb-5">{restaurant.location_text}</div>
                      </div>
                    </Link>
                  </div>
                ))}
          </React.Suspense>
        </div>
        <div className=" place-items-center text-center">
          <Link
            title="من نحن"
            rel="external allRestaurants dofollow"
            className="w-full cursor-pointer"
            to="/about"
            onClick={() => gaEventTracker("About us page")}
          >
            <h1 className="py-10 my-10">سجل في خدمة الفلل في أريحا</h1>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AllRestaurants;
